
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import EditKmpModal from "@/components/modals/forms/EditKmpModal.vue";

// console.log(this.$route.query.test)

export default defineComponent({
  props: 
  {
      id: { required: true }
  },
  name: "apps-companies-kmps-details",
  components: {
   EditKmpModal
  },
  methods: {
    
  },
  setup(props) {

    var ids = ref(props.id);
    const store = useStore();

   interface ViewKmp {
      kmp_id,
      kmp_name,
      kmp_din,
      kmp_designation_name,
      kmp_gender_name,
      kmp_email_id,
      kmp_mobile_no,
      kmp_dob,
      active : {
        label :string;
        color :string;
      }
    };    
    
    var resultsView = ref<ViewKmp>();
  
    resultsView.value = {
      kmp_id: "",
      kmp_name: "",
      kmp_din: "",
      kmp_designation_name: "",
      kmp_gender_name: "",
      kmp_email_id: "",
      kmp_mobile_no: "",
      kmp_dob: "",
      active: {
        label: "",
        color: "",
      }
    }

    const getGradeDetails = async () => {
     
      try {

      var values = { "kmp_id"  :  props.id, "kmp_din"  :  "", "page"  : 1, "records_per_page" : 1}

      await store.dispatch(Actions.CUST_GET_KMP_LIST, values).then(({ data }) => {

        var status_label = ""
        var status_color = ""
        var active_label = ""
        var active_color = ""

        if (data.active){
            active_label = "Yes";
            active_color = "success";                  
        }else{
            active_label = "No";
            active_color = "danger";
        }
     
        resultsView.value = {
          kmp_id :  data.kmp_id,
          kmp_name : data.kmp_name,
          kmp_din: data.kmp_din,
          kmp_designation_name: data.kmp_designation_name,
          kmp_gender_name: data.kmp_gender_name,
          kmp_email_id: data.kmp_email_id,
          kmp_mobile_no: data.kmp_mobile_no,
          kmp_dob: data.kmp_dob,
          active: {
            label: active_label,
            color: active_color
          },
        }

      })
      .catch(({ response }) => {

        console.log(response);

      });
        
      } catch (e) {
        console.log(e);
      }
    };        
    
    
    onMounted( async () => {
      await getGradeDetails();
      setCurrentPageBreadcrumbs("KMP Details", ["KMP"]);
    });


    return {
      resultsView,
      ids,
    };

  }
});
